import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { HorizontalLine } from '../components/horizontal-line';
import { Layout } from '../components/layout';
import { ContentWrapper, PageWrapper } from '../components/layout/wrappers';
import { HighlightAlt, PageTitle, PrimaryText } from '../components/typography';
import { colors } from '../modules/colors';

const SectionStyle = createOwnUpStyle({
  marginBottom: 16
});

const Section = createOwnUpComponent('div', SectionStyle);

const EquationWrapperStyle = createOwnUpStyle({
  backgroundColor: colors.LIGHT_GREY,
  width: 'fit-content'
});

const EquationWrapper = createOwnUpComponent('div', EquationWrapperStyle);

const EquationText = styled(OwnUpText)`
  font-family: Monosten-Medium;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${colors.PRIMARY};
  display: block;
  font-style: normal;
  font-size: 16px;
  line-height: 1.8;
  padding: 20px;
`;

// eslint-disable-next-line max-lines-per-function
export const Methodology = () => (
  <Layout>
    <PageWrapper>
      <ContentWrapper>
        <PageTitle variant="title">Savings Methodology</PageTitle>
        <Section>
          <PrimaryText>
            Customers who secure a mortgage from the Own Up marketplace{' '}
            <HighlightAlt>save an average of $27,102</HighlightAlt> in interest over the life of
            their loan.
          </PrimaryText>
        </Section>

        <Section>
          <PrimaryText>Here&apos;s how we calculate that number:</PrimaryText>
        </Section>

        <Section>
          <EquationWrapper>
            <EquationText>
              <div style={{ paddingLeft: 20 }}>(Interest cost of average loan in U.S.)</div>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: 10 }}>&#8722;</div>
                <div>(Interest cost of loan in our marketplace)</div>
              </div>
              <HorizontalLine aria-describedby="equals" />
              <div hidden aria-label="equals" id="equals" />
              <div style={{ paddingLeft: 20 }}>$27,102</div>
            </EquationText>
          </EquationWrapper>
        </Section>

        <Section>
          <PrimaryText>
            To find the total interest cost on the average loan, we use the Optimal Blue Mortgage
            Market Indices (the most widely used market average) to determine the national average
            interest rate on the same day as our comparison loan was secured from the Own Up
            marketplace.
          </PrimaryText>
        </Section>

        <Section>
          <PrimaryText>
            We use that national average interest rate to determine the total interest cost of the
            average loan. Interest can vary based on many factors, so we limited the analyses to 30
            year conventional mortgages in 2019 but did not normalize for other factors that can
            influence rate (e.g., credit score, state, property type).
          </PrimaryText>
        </Section>
      </ContentWrapper>
    </PageWrapper>
  </Layout>
);

export default Methodology;
